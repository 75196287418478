import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const marseilleCustomerGroup = 'marseille_food_market';
export const customerGroup = marseilleCustomerGroup;

const supportEmail = {
	marseille_food_market: 'marseille@smoothr.de'
};
const firebaseConfig = {
	marseille_food_market: {
		apiKey: 'AIzaSyBplxv1KpMXUSgrF7XGTqC7ktgvnqyk-dI',
		authDomain: 'marseille-ab433.firebaseapp.com',
		projectId: 'marseille-ab433',
		storageBucket: 'marseille-ab433.appspot.com',
		messagingSenderId: '356800196644',
		appId: '1:356800196644:web:5bbf4288854f53d15a28b1'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyASyhGnKtZa_Id2l-Gp9_vWIDDIIvlK2OE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de']
};

import 'zone.js/dist/zone-error';
