import {HomePage} from './../home/home.page';
import {Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ToOrderButtonComponent} from '../../components/to-order-button/to-order-button.component';
import {AlertController, IonSearchbar, ModalController} from '@ionic/angular';
import {ModalInfoComponent} from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {AppComponent} from '../../app.component';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrderPage} from '../order/order.page';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {
	axiosErrorToMessage,
	defaultsToArticleOption,
	getAvailability,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';

enum toggleTags {
	All = 'all',
	Vegeterian = 'veggie'
}
@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss']
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, {static: false}) toOrderButton;
	@ViewChild('articleContent', {static: false}) articleList;
	@ViewChild(IonSearchbar, {static: false}) searchBar;
	@ViewChild('categoryList', {static: false}) categoryList;

	@ViewChild('shopListElement', {static: false}) shopListElement;

	toggleTagsEnum = toggleTags;
	selectedToggleTag = toggleTags.All;
	environment = environment;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	PreorderType = PreorderType;
	searchBarOpen = false;
	shopList = [
		{
			title: 'select_restaurant.brand_la_centrale_bar',
			info: 'select_restaurant.brand_la_centrale_bar_info',
			tag: 'brand_la_centrale_bar',
			img: '/assets/marseille/shops/brand_la_centrale_bar.png'
		},
		{
			title: 'select_restaurant.brand_pokawa',
			info: 'select_restaurant.brand_pokawa_info',
			tag: 'brand_pokawa',
			img: '../../../assets/marseille/shops/brand_pokawa.svg'
		},
		{
			title: 'select_restaurant.brand_croquorico',
			info: 'select_restaurant.brand_croquorico_info',
			tag: 'brand_croquorico',
			img: '../../../assets/marseille/shops/brand_croquorico.svg'
		},
		{
			title: 'select_restaurant.brand_bricoleurs_de_douceurs',
			info: 'select_restaurant.brand_bricoleurs_de_douceurs_info',
			tag: 'brand_bricoleurs_de_douceurs',
			img: '../../../assets/marseille/shops/brand_bricoleurs_de_douceurs.svg'
		},
		{
			title: 'select_restaurant.brand_emkipop',
			info: 'select_restaurant.brand_emkipop_info',
			tag: 'brand_emkipop',
			img: '../../../assets/marseille/shops/brand_emkipop.svg'
		}
	];
	private _selectedShopValue: string = 'brand_la_centrale_bar';
	get selectedShopCategory() {
		return this._selectedShopValue;
	}
	get preorderType(): PreorderType {
		return this.order.preorder?.type;
	}

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	private _selectedCategoryIndex = 0;

	get selectedCategoryIndex(): number {
		return this._selectedCategoryIndex;
	}

	set selectedCategoryIndex(value) {
		if (this._selectedCategoryIndex === value) {
			return;
		}
		this._selectedCategoryIndex = value;
		this.scrollTo(value);
	}

	get selectedCategory(): ArticleCategory {
		return this.categories[this.selectedCategoryIndex];
	}

	get active(): boolean {
		return (
			this.order &&
			OrderUtils.articleGroupsTotalPrice(
				this.order.orderedArticles,
				this.order.type,
				this.order.preorder.type,
				this.order.terminalorder ? this.order.terminalorder.type : null
			) > 0
		);
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	async ngOnInit() {
		await super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000
				});
				break;
		}

		this.loading = true;
		this.repository.venue.emit(
			await this.repository.getVenue(
				this.order && this.order.venue ? this.order.venue : environment.customerGroup + '_master'
			)
		);
		this.reloadCategories();

		this.loading = false;
		this.route.queryParams.subscribe(v => {
			console.log(v);
			if (v?.tag) {
				this._selectedShopValue = v.tag;
				this.scrollToTag(v.tag, v?.categoryId);
			}
		});
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}
	async scrollToTag(tag: string, categoryId: string) {
		const findIndex = this.shopList.findIndex(it => it.tag == tag);
		setTimeout(() => {
			if (
				!this.shopListElement?.nativeElement?.children[findIndex] ||
				(!this.shopListElement?.nativeElement?.scrollTo && !this.shopListElement?.nativeElement?.scrollLeft)
			) {
				return;
			}
			this.shopListElement.nativeElement.scrollTo(
				this.shopListElement.nativeElement.children[findIndex].offsetLeft - 25,
				0
			);
			if (categoryId) {
				const foundIndexOfSelectedCategoryIndex = this.categories.findIndex(it => it._id === categoryId);
				if (foundIndexOfSelectedCategoryIndex >= 0) {
					this.selectedCategoryIndex = foundIndexOfSelectedCategoryIndex;
					this.scrollArticleListTo(foundIndexOfSelectedCategoryIndex);
					this.scrollCategoryListTo(foundIndexOfSelectedCategoryIndex);
				}
			}
		}, 500);
		this.reloadCategories();
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			await sleep(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		if (this.selectedCategoryIndex !== index) {
			this.selectedCategoryIndex = index;
			await this.scrollArticleListTo(index);
			await this.scrollCategoryListTo(index);
		}
		this.scrollEvents = true;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategoryIndex = categoryIndex;
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await sleep(100);
	}
	async openModal(item: Article) {
		if (this.loading) {
			return;
		}
		// if (
		// 	this.order.tableNumber === 'preorder_table' &&
		// 	this.order.preorder &&
		// 	this.order.preorder.type === PreorderType.INSIDE
		// ) {
		// 	this.openSelectTableModal();
		// 	return;
		// }
		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...defaultsToArticleOption(item, [], item.defaults, this.order.preorder.type));
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup
			},
			mode: 'ios',
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);
			this.repository.order.emit(this.order);
		}
	}

	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
	}

	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.filter(category => {
				if (this.selectedShopCategory) {
					return !!category.tags.find(tag => tag.identifier === this.selectedShopCategory);
				} else {
					return false;
				}
			})
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = getAvailability(article, OrderType.PREORDER, this.preorderType);
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase()
						];
						return available && keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					return available;
				});

				if (this.selectedToggleTag !== toggleTags.All) {
					cat.articles = cat.articles.filter(article => {
						if (this.selectedToggleTag === toggleTags.Vegeterian) {
							return article.compound?.features?.vegetarian || article.compound?.features?.vegan;
						} else {
							return false;
						}
					});
				}
				return cat;
			})
			.filter(category => category.articles.length > 0);

		return;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(this.order && this.order.venue)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {
					this.reloadCategories();
					this.selectedCategoryIndex = 0;
				});
			return;
		}
		this.reloadCategories();
		this.selectedCategoryIndex = 0;
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	private async scrollArticleListTo(index: number) {
		if (!this.articleList) {
			return;
		}
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop + 1, 300);
	}
	goToHome() {
		HomePage.navigate(this.router);
	}

	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}

	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}
	async openSelectTableModal(openByToolbar?: boolean) {
		if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
			if (this.order.tableNumber === 'preorder_table' || openByToolbar) {
				const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
				if (selectTable) {
					this.order.tableNumber = selectTable.table;
					this.order.table = selectTable.tableId;
					this.repository.order.emit(this.order);
				}
			}
		}
	}
	selectShop(value: string) {
		this._selectedShopValue = value;
		this.scrollToTag(value, null);
		this.scrollTo(0);
	}
	togleSwitch(selectedItem: toggleTags) {
		this.selectedToggleTag = selectedItem;
		this.reloadCategories();
	}
}
