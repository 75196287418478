import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {getAvailability, sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import {HomePage} from '../home/home.page';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-select-restaurant',
	templateUrl: './select-restaurant.page.html',
	styleUrls: ['select-restaurant.page.scss']
})
export class SelectRestaurantPage extends RepositoryDirective implements OnInit {
	static url = 'select-restaurant';
	venue: Venue;
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	shopList = [
		{
			title: 'select_restaurant.brand_la_centrale_bar_title',
			info: 'select_restaurant.brand_la_centrale_bar_info',
			tag: 'brand_la_centrale_bar',
			img: '/assets/marseille/shops/brand_la_centrale_bar.png',
			categories: [] as ArticleCategory[]
		},
		{
			title: 'select_restaurant.brand_pokawa_title',
			info: 'select_restaurant.brand_pokawa_info',
			tag: 'brand_pokawa',
			img: '../../../assets/marseille/shops/brand_pokawa.svg',
			categories: [] as ArticleCategory[]
		},
		{
			title: 'select_restaurant.brand_croquorico_title',
			info: 'select_restaurant.brand_croquorico_info',
			tag: 'brand_croquorico',
			img: '../../../assets/marseille/shops/brand_croquorico.svg',
			categories: [] as ArticleCategory[]
		},
		{
			title: 'select_restaurant.brand_bricoleurs_de_douceurs_title',
			info: 'select_restaurant.brand_bricoleurs_de_douceurs_info',
			tag: 'brand_bricoleurs_de_douceurs',
			img: '../../../assets/marseille/shops/brand_bricoleurs_de_douceurs.svg',
			categories: [] as ArticleCategory[]
		},
		{
			title: 'select_restaurant.brand_emkipop_title',
			info: 'select_restaurant.brand_emkipop_info',
			tag: 'brand_emkipop',
			img: '../../../assets/marseille/shops/brand_emkipop.svg',
			categories: [] as ArticleCategory[]
		}
	];
	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private snackBar: MatSnackBar
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(SelectRestaurantPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		console.log('Select Restaurant', this.venue);
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(this.order && this.order.venue)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {});
			return;
		}

		if (this.venue) {
			this.getCategories(this.venue);
		}
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	navigateToShop(tag: string) {
		this.router.navigate(['/menu'], {
			queryParams: {
				tag
			}
		});
	}
	getCategories(venue: Venue) {
		if (!venue) {
			return;
		}
		const categories = (venue.articleCategories ?? [])
			.filter(category => !category.hidden)
			.map(category => {
				category.articles = category.articles.filter(art => {
					const available = getAvailability(art, OrderType.PREORDER, this.order?.preorder?.type);
					return available;
				});
				return category;
			});

		this.shopList = this.shopList.map(it => {
			it.categories = [];
			categories.forEach(category => {
				if (category.tags.length > 0 && category.tags.find(tag => tag.identifier === it.tag)) {
					it.categories.push(category);
				}
			});
			return it;
		});
	}
	async goHome() {
		await HomePage.navigate(this.router);
	}
	navToShopWithPreSelectedCategory(shopTag: string, categoryId: string, ifCategoryAvaible: boolean = false) {
		if (ifCategoryAvaible) {
			this.snackBar.open('Sorry this category now is not active...', null, {
				duration: 3000
			});
			return;
		}
		if (shopTag && categoryId) {
			this.router.navigate(['/menu'], {
				queryParams: {
					tag: shopTag,
					categoryId: categoryId
				}
			});
		}
	}
}
