import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {
	defaultsToArticleOption,
	getBasePrice,
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {AllergensInfoModalComponent} from '../allergens-info-modal/allergens-info-modal.component';
import {ModalController} from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import {ValidationUtils} from 'src/smoothr-web-app-core/utils/validation-utils';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	@Input() order: Order;
	priceText = '';
	numberToCurrency = numberToCurrency;

	constructor(
		private modalCtrl: ModalController,
		public translate: TranslateService
	) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPriceNew();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPriceNew();
	}

	private reloadPrice() {
		if (this.article) {
			if (this.article.isFromPrice) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = defaultsToArticleOption(
					this.article,
					[],
					this.article.defaults,
					this.order?.preorder?.type
				);
				articleGroup.quantity = 1;
				this.price = this.price = this.getArticlePrice(
					this.article,
					OrderUtils.totalPrice(articleGroup, this.order?.type, this.order?.preorder?.type)
				);
			} else {
				this.price = this.getArticlePrice(
					this.article,
					getPrice(this.article, this.order?.type, this.order?.preorder?.type)
				);
			}
		} else {
			this.price = 0;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
		console.log(this.article.name.de, this.price);
	}
	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}
		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}
	private reloadPriceNew() {
		const articleGroup = new ArticleGroup();
		articleGroup.article = this.article;
		articleGroup.groups = defaultsToArticleOption(this.article, [], [], this.order?.preorder?.type);

		articleGroup.quantity = 1;

		const article = articleGroup.article;
		let price = numberD(article.price);
		const processedGroups: string[] = [];
		article.groups.forEach(grp => {
			if (processedGroups.indexOf(grp._id) >= 0) return;
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, grp).times < 0) return;
			if (grp.requiredAmount == grp.limit) {
				const minimumItem = this.minOfItem(grp.articles, opt => numberD(opt.price));

				price += numberD(minimumItem.price) * grp.requiredAmount;
				const dependants = this.dependenciesRegardingOptionArticle(article, article.groups, minimumItem);
				dependants.forEach(dependant => {
					const dependantMinimumItem = this.minOfItem(dependant.articles, opt => numberD(opt.price));
					price += grp.requiredAmount * dependant.requiredAmount * numberD(dependantMinimumItem.price);
					processedGroups.push(dependant._id);
				});
				processedGroups.push(grp._id);
			}
		});

		this.price = price;
		if (!this.price) {
			return '';
		}
		const tagPrice = this.findTagPrice(this.article);
		if (tagPrice && tagPrice > 0) {
			this.price = this.price - tagPrice;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
		return;
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}
	getArticlePrice(article: Article, price: number) {
		let totalPrice = price;
		const tagPrice = this.findTagPrice(article);
		if (tagPrice && tagPrice > 0) {
			totalPrice = totalPrice - tagPrice;
		}
		return totalPrice;
	}

	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article?.tags?.find(
				it => it?.identifier === 'deposit_25' || it?.identifier === 'deposit_15' || it?.identifier === 'deposit_8'
			);
			if (foundTag) {
				return foundTag.identifier === 'deposit_25'
					? 0.25
					: foundTag.identifier === 'deposit_15'
					? 0.15
					: foundTag.identifier === 'deposit_8'
					? 0.8
					: 0;
			}
		} else {
			return null;
		}
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15') ? 'deposit15' : '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8') ? 'deposit8' : '';

		return deposit15 || deposit25 || deposit8;
	}

	showMeasurement(article: Article) {
		if (article) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					return (
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency)
					);
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					return (
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency)
					);
				}
				return (
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency)
				);
			}
		} else {
			return '';
		}
	}
	async showAllergens(article: Article) {
		await AllergensInfoModalComponent.show(this.modalCtrl, article);
	}
	updateUrl(url: string) {
		return '../../../assets/marseille/logo.svg';
	}
	isTagPriceFrom() {
		return this.article &&
			this.article?.groups?.length > 0 &&
			this.article?.groups?.find(it => it?.articles?.find(it => numberD(it?.price) > 0))
			? this.translate.instant('item_view.price_from')
			: '';
		return this.article &&
			this.article?.tags?.length > 0 &&
			this.article?.tags?.find(tag => tag?.identifier === 'fromPrice')
			? this.translate.instant('item_view.price_from')
			: '';
	}
}
